import React from "react"
import Layout from "../components/layout-careers"
// homepageComponents
import CareersSplash from "../components/careers/splash"

const IndexPage = () => (
  <Layout splash={<CareersSplash />}>
    <div>
      <h2 className="title__careers">
        If you meet the following criteria, there may be a fit for you on our
        team!
      </h2>
      <ul className="list-careers">
        <li>
          7 years digital agency experience required, with at least 5 of those 7
          years in paid media &amp; analytics
        </li>
        <li>
          Proficient with Google Adwords (search, display, and retargeting) and
          Bing Ads
        </li>
        <li>Google Adwords Certified for at least 7 years</li>
        <li>Google Analytics Certified for at least 7 years</li>
        <li>
          Proficient with Google Tag Manager (Proficiency with other analytics
          tools like Mixpanel, Amplitude preferred, but not required)
        </li>
        <li>
          Proficient in ad serving and tracking using pixels, URL parameters,
          and UTM codes.
        </li>
        <li>
          Must be an Excel wizard, manipulating large data sets, vlookup and
          creating &amp; analyzing pivot tables.
        </li>
        <li>Bing Ads Accredited for at least 5 years</li>
        <li>
          Expert in conversion tracking and phone call tracking software
          (CallRail, Call Tracking Metrics, etc)
        </li>
        <li>
          At least 5 years experience managing paid media budgets of $50k+ per
          month is required
        </li>
        <li>
          Familiarity working with 3rd party demand-side platforms (DSPs) for
          programmatic campaigns and media buys
        </li>
        <li>
          Working with designers and developers to implement new creative
          (landing pages, websites, banner ads) to support search engine
          marketing campaigns
        </li>
        <li>
          Experience being involved in CRO projects, A/B testing and platforms
          such as Optimizely, Unbounce, Monetate
        </li>
        <li>
          Genuine passion for online marketing &amp; paid search in particular!
        </li>
      </ul>
      <div className="txt-about-summary">
        Think you are a good fit? <br /> Please email{" "}
        <a href="mailto:careers@medicalmarketingarm.com">
          careers@medicalmarketingarm.com
        </a>{" "}
        if you're interested in joining the team!
      </div>
    </div>
  </Layout>
)

export default IndexPage
