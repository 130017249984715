/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import Chat from "./chat"


import "../assets/css/mma-styles.css"

const Layout = ({ children, splash }) => {
  const layouClass = "mma-theme page-template-page-careers"

  return (
    <div className={layouClass} id="page">
      <div className="header-holder">
        <div className="header-holder__wrap">
          <Header />
          {splash}
        </div>
      </div>
      <div className="inner-body">
        <div className="container">
          <div className="careers-content">{children}</div>
        </div>
      </div>
      <Footer />
      <Chat />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
