import React from "react"
// images
import BirthdaysImg from "../../assets/images/careers/birthdays.png"
import BonusImg from "../../assets/images/careers/bonus.png"
import FlexiblehoursImg from "../../assets/images/careers/flexiblehours.png"
import GrowthImg from "../../assets/images/careers/growth.png"
import HealthImg from "../../assets/images/careers/health.png"
import HolidaysImg from "../../assets/images/careers/holidays.png"
import MeetingsImg from "../../assets/images/careers/meetings.png"
import MoneyImg from "../../assets/images/careers/money.png"
import PantryImg from "../../assets/images/careers/pantry.png"
import RemoteImg from "../../assets/images/careers/remote.png"
import RetirementImg from "../../assets/images/careers/retirement.png"
import SalarytImg from "../../assets/images/careers/salary.png"

const Splash = () => (
  <div className="careers-splash">
    <div className="container">
      <header className="careers-splash__head">
        <h1>
          Iron sharpens iron. <br /> Interested in joining our team?{" "}
        </h1>
        <p>
          Our door is always open for the smartest, strongest, and bravest
          talent to continue our evolution.{" "}
        </p>{" "}
      </header>
      <div className="perks-wrap">
        <h3 className="ttl-perks">Perks &amp; Benefits</h3>
        <div className="careers-perks">
          <div className="perks-block">
            <figure>
              <img src={SalarytImg} alt="Competitive Salary" />
            </figure>
            <strong className="perks-block__title">Competitive Salary</strong>
          </div>
          <div className="perks-block">
            <figure>
              <img src={HealthImg} alt="Health Insurance" />
            </figure>
            <strong className="perks-block__title">Health Insurance</strong>
          </div>
          <div className="perks-block">
            <figure>
              <img src={RetirementImg} alt="Retirement Plan" />
            </figure>
            <strong className="perks-block__title">Retirement Plan</strong>
          </div>
          <div className="perks-block">
            <figure>
              <img src={HolidaysImg} alt="10.5 Paid Holidays" />
            </figure>
            <strong className="perks-block__title">10.5 Paid Holidays</strong>
          </div>
          <div className="perks-block">
            <figure>
              <img src={GrowthImg} alt="Career Growth" />
            </figure>
            <strong className="perks-block__title">Career Growth</strong>
          </div>
          <div className="perks-block">
            <figure>
              <img src={BonusImg} alt="Bonus Program" />
            </figure>
            <strong className="perks-block__title">Bonus Program</strong>
          </div>
          <div className="perks-block">
            <figure>
              <img src={RemoteImg} alt="Remote Work" />
            </figure>
            <strong className="perks-block__title">Remote Work</strong>
          </div>
          <div className="perks-block">
            <figure>
              <img src={FlexiblehoursImg} alt="Flexible Hours" />
            </figure>
            <strong className="perks-block__title">Flexible Hours</strong>
          </div>
          <div className="perks-block">
            <figure>
              <img src={MoneyImg} alt="Make Our Clients Money" />
            </figure>
            <strong className="perks-block__title">
              Make Our Clients Money
            </strong>
          </div>
          <div className="perks-block">
            <figure>
              <img src={MeetingsImg} alt="Mentorship Meetings" />
            </figure>
            <strong className="perks-block__title">Mentorship Meetings</strong>
          </div>
          <div className="perks-block">
            <figure>
              <img src={PantryImg} alt="Stocked Pantry" />
            </figure>
            <strong className="perks-block__title">Stocked Pantry</strong>
          </div>
          <div className="perks-block">
            <figure>
              <img src={BirthdaysImg} alt="Birthday Time Off" />
            </figure>
            <strong className="perks-block__title">Birthday Time Off</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Splash
